<template>
    <div>
        <!-- Início detalhes -->
        <v-dialog
            v-model="modal.detalhes"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                    color="#018656"
                    dark
                >
                    <v-icon
                        class="ml-2"
                    >
                        fas fa-boxes
                    </v-icon>
                    <v-toolbar-title
                        class="pl-4"
                    >
                        Detalhes do objeto ({{ objetoAtual }})
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-app-bar-nav-icon @click="modal.detalhes = false">
                        <v-icon>fas fa-times</v-icon>
                    </v-app-bar-nav-icon>
                </v-toolbar>
                <v-card-text>
                    <v-col
                        class="pt-6"
                    >
                        <v-progress-circular
                            v-if="modal.loading"
                            indeterminate
                            color="primary"
                            width="2"
                            style="width: 100%;"
                        ></v-progress-circular>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    dense
                                    outlined
                                    label="Protocolo de reclamação"
                                    type="text"
                                    autocomplete="off"
                                    clearable
                                    append-icon="fas fa-save"
                                    @click:append="salvarProtocoloReclamacao"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6"></v-col>
                            <v-col cols="3">
                                <v-text-field
                                    dense
                                    outlined
                                    label="Nº rastreio"
                                    type="text"
                                    autocomplete="off"
                                    v-model="chamados.numero_rastreio"
                                    clearable
                                    append-icon="fas fa-search"
                                    @click:append="carregarDetalhesBusca(chamados.numero_rastreio)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <b>Enviado</b>
                                <v-row>
                                    <v-col cols="12" style="padding-bottom: 0px;">
                                        Data postagem: {{ detalhes.enviado.data_postagem }}
                                    </v-col>
                                    <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                        Data considerada: {{ detalhes.enviado.data_considerada }}
                                    </v-col>
                                    <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                        de
                                    </v-col>
                                    <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                        Cep: {{ detalhes.enviado.cep }}
                                    </v-col>
                                    <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                        Cidade: {{ detalhes.enviado.cidade }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <b>Para</b>
                                <v-row>
                                    <v-col cols="12" style="padding-bottom: 0px;">
                                        Nome: {{ detalhes.para.nome }}
                                    </v-col>
                                    <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                        Cep: {{ detalhes.para.cep }}
                                    </v-col>
                                    <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                        Cidade: {{ detalhes.para.cidade }}
                                    </v-col>
                                    <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                        Bairro: {{ detalhes.para.bairro }}
                                    </v-col>
                                    <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                                        Rua: {{ detalhes.para.rua }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>
                                <b>Previsão de Entrega</b>
                                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px;">
                                    Data: {{ detalhes.previsao_de_entrega.data }}
                                </v-col>
                                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px;">
                                    Em domicilio: {{ detalhes.previsao_de_entrega.em_domicilio }}
                                </v-col>
                            </v-col>
                            <v-col>
                                <b>Entregue em</b>
                                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px;">
                                    Data: {{ detalhes.entregue_em.data }}
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                Rastreio do objeto
                            </v-col>
                            <v-row>
                                <v-col cols="12" class="px-6">
                                    <v-data-table
                                        dense
                                        :headers="grid.detalhes.cabecalho"
                                        :items="grid.detalhes.items"
                                        hide-default-footer
                                        class="elevation-1"
                                        :loading="grid.detalhes.carregando"
                                        item-key="codigo"
                                    >
                                        <template #item.data_hora="{ item }">{{ formatarDataBR(item.data) }} {{ item.hora }}</template>
                                        <template #item.origem_cidade_uf_local="{ item }">{{ item.cidade }} {{ item.cidade != null && item.uf != null ? '/' : ''}} {{ item.uf }} {{ item.local != null && item.uf != null ? '-' : ''}} {{ item.local }}</template>
                                        <template #item.destino_cidade_uf_local="{ item }">{{ item.destino_cidade }} {{ item.destino_cidade != null && item.destino_uf != null ? '/' : ''}} {{ item.destino_uf }} {{ item.destino_local != null && item.destino_uf != null? '-' : ''}} {{ item.destino_local }}</template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-col>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Fim Detalhes -->

        <!-- Início principal -->
        <v-card>
            <v-toolbar
                flat
            >
                <v-icon>
                    fas fa-headset
                </v-icon>
                <v-toolbar-title
                    class="pl-4"
                >
                    Listagem de chamados
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon @click="fechar">
                    <v-icon>fas fa-times</v-icon>
                </v-app-bar-nav-icon>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col
                        cols="2"
                        class="px-6 py-6"
                        style="padding-bottom: 0px !important;"
                    >
                        <v-select
                            dense

                            label="Resultados por página"
                            :items="grid.chamados.qtdsPaginacao"
                            item-text="text"
                            item-value="value"
                            v-model="grid.chamados.porPagina"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="3"
                        class="px-6 py-6"
                        style="padding-bottom: 0px !important;"
                    >
                        <v-select
                            dense
                            label="Ordenar por"
                            :items="grid.chamados.ordenacao.colunas"
                            item-text="text"
                            item-value="value"
                            v-model="grid.chamados.coluna"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="2"
                        class="px-6 py-6"
                        style="padding-bottom: 0px !important;"
                    >
                        <v-select
                            dense
                            label="Direção"
                            :items="grid.chamados.ordenacao.direcao"
                            item-text="text"
                            item-value="value"
                            v-model="grid.chamados.direcao"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="5"
                        class="px-6 py-6"
                        style="padding-bottom: 0px !important;"
                    >
                        <v-text-field
                            dense
                            label="Pesquisar"
                            type="text"
                            autocomplete="off"
                            clearable
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        class="px-6 py-6"
                    >
                        <v-data-table
                            dense
                            :headers="grid.chamados.cabecalho"
                            :items="grid.chamados.items"
                            :items-per-page="grid.chamados.porPagina"
                            hide-default-footer
                            class="elevation-1"
                            :loading="grid.chamados.carregando"
                            item-key="codigo"
                            v-model="grid.chamados.itemSelecionado"
                            show-expand
                            single-expand
                        >
                            <template #item.numero_rastreio="{ item }">
                                <a @click="carregarDetalhes(item)" style="text-decoration: none;">
                                  {{ item.numero_rastreio }}
                                </a>
                            </template>

                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row
                    class="mt-4"
                >
                    <v-col
                        cols="2"
                    >
                        <v-btn
                            fab
                            small
                            color="primary"
                            dark
                            @click="voltarTopo()"
                            style="margin-left: 10px;"
                        >
                            <v-icon>
                                fas fa-arrow-up
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col
                        cols="8"
                    >
                        <v-pagination
                            v-model="grid.chamados.paginaAtual"
                            :length="Math.ceil(grid.chamados.totalRegistros / this.grid.chamados.porPagina)"
                            @input="mudarPagina"
                            :total-visible="5"
                        ></v-pagination>
                    </v-col>
                    <v-col
                        cols="2"
                    >
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- Fim principal -->
    </div>
</template>

<script>
import { baseApiUrl } from '@/global'
import axios from 'axios'
import moment from 'moment'

export default {
    name: 'Chamados',
    data: () => ({
        grid: {
            chamados: {
                cabecalho: [
                    { text: '#', value: 'codigo', sortable: false },
                    { text: 'Rastreio', value: 'numero_rastreio', sortable: false },
                    { text: 'PI', value: 'protocolo_retorno', sortable: false },
                    { text: 'Dt. Prev', value: 'data_prevista', formatter: (x) => (x ? moment(x).format('DD/MM/yyyy') : null), sortable: false },
                    // { text: 'Status', value: 'status', sortable: false  },
                    // { text: 'Dt. Mov.', value: 'data', formatter: (x) => (x ? moment(x).format('DD/MM/yyyy HH:mm:ss') : null), sortable: false },
                    // { text: 'Movimentação', value: 'descricao', sortable: false },
                    { text: 'Motivo', value: 'motivo', sortable: false },
                    { text: 'Retorno', value: 'msg_retorno', sortable: false },
                ],
                items: [],
                carregando: false,
                paginaAtual: 1,
                totalRegistros: 0,
                qtdsPaginacao: [
                    10,
                    15,
                    50,
                    100,
                ],
                porPagina: 10,
                itemSelecionado: [],
                coluna: 'reclamacoes.codigo',
                direcao: 'ASC',
                ordenacao: {
                    colunas: [
                        { text: 'Código', value: 'reclamacoes.codigo' },
                        { text: 'Rastreio', value: 'reclamacoes.numero_rastreio' },
                        { text: 'PI', value: 'reclamacoes.protocolo_retorno' },
                        { text: 'Dt. Prev', value: 'objetos.data_prevista' },
                        // { text: 'Status', value: 'reclamacoes.status' },
                        // { text: 'Dt. Mov.', value: 'reclamacoes.data' },
                        // { text: 'Movimentacao', value: 'relamacoes.descricao' },
                        { text: 'Motivo', value: 'reclamacoes.motivo' },
                        { text: 'Retorno', value: 'reclamacoes.msg_retorno' },
                    ],
                    direcao: [
                        { text: 'Asc', value: 'ASC' },
                        { text: 'Desc', value: 'DESC' },
                    ],
                },
            },
            detalhes: {
                cabecalho: [
                    { text: 'Status', value: 'descricao', sortable: false },
                    { text: 'Data', value: 'data_hora', sortable: false, formatter: (x) => (x ? moment(x).format('DD/MM/yyyy HH:mm:ss') : null), },
                    { text: 'Origem', value: 'origem_cidade_uf_local', sortable: false },
                    { text: 'Destino', value: 'destino_cidade_uf_local', sortable: false },
                ],
                items: [],
                carregando: false,
            }
        },
        modal: {
            filtro: false,
            dt_considerada: false,
            dt_prevista: false,
            dt_entrega: false,
            dt_ultima_posicao: false,
            rastreio: false,
            detalhes: false,
            loading: false,
            generateFile: true,
        },
        objetoAtual: '',
        chamados: [],
        detalhes: [],
        chamadoAtual: '',
        loading: false,
        detalhes: {
            enviado: {
                data_postagem: '',
                data_considerada: '',
                cep: '',
                cidade: '',
            },
            para: {
                nome: '',
                cep: '',
                cidade: '',
                bairro: '',
                rua: '',
            },
            previsao_de_entrega: {
                data: '',
                em_domicilio: '',
            },
            entregue_em: {
                data: '',
            },
        },
    }),
    mounted () {
        this.carregarChamados()
    },
    methods: {
        fechar () {
            this.$router.push('/')
        },
        carregarChamados () {
            this.grid.chamados.items = []
            this.grid.chamados.carregando = true
            let url = `${baseApiUrl}/chamados?page=${this.grid.chamados.paginaAtual}`
            url += `&coluna=${this.grid.chamados.coluna}&direcao=${this.grid.chamados.direcao}`
            if (Number(this.grid.chamados.porPagina) > 0)
                url += `&por_pagina=${this.grid.chamados.porPagina}`
            axios.get(url)
                .then(res => {
                    this.grid.chamados.items = res.data.dados.data
                    this.grid.chamados.totalRegistros = res.data.dados.total
                    this.grid.chamados.porPagina = Number(res.data.dados.per_page)
                })
                .finally(() => this.grid.chamados.carregando = false)
        },
        mudarPagina (pagina) {
            this.grid.chamados.paginaAtual = pagina
            this.carregarChamados()
        },
        voltarTopo () {
            window.scrollTo(0, 0)
        },
        formatarNumeroBR (value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        formatarDataBR (value) {
            if (value) {
                return moment(String(value)).format('DD/MM/yyyy')
            }
        },
        carregarDetalhes (item) {
            this.objetoAtual = item.numero_rastreio
            this.chamados.numero_rastreio = item.numero_rastreio
            this.modal.detalhes = true
            this.grid.detalhes.carregando = true
            let url = `${baseApiUrl}/rastreios?numero_rastreio=${item.numero_rastreio}`
            axios.get(url)
                .then(res => {
                    this.grid.detalhes.items = res.data.dados
                })
                .finally(() => this.grid.detalhes.carregando = false)

            this.grid.detalhes.carregando = true

            url = `${baseApiUrl}/detalhes?numero_rastreio=${item.numero_rastreio}`
            axios.get(url)
                .then(res => {
                    this.detalhes.enviado.data_postagem = moment(res.data.dados[0].data_postagem).format('DD/MM/yyyy')
                    this.detalhes.enviado.data_considerada = moment(res.data.dados[0].data_considerada).format('DD/MM/yyyy')
                    this.detalhes.enviado.cep = res.data.dados[0].remetente_cep
                    this.detalhes.enviado.cidade = res.data.dados[0].remetente_cidade + '/' + res.data.dados[0].remetente_estado
                    this.detalhes.para.nome = res.data.dados[0].destinatario_nome
                    this.detalhes.para.cep = res.data.dados[0].destinatario_cep
                    this.detalhes.para.cidade = res.data.dados[0].destinatario_cidade + '/' + res.data.dados[0].destinatario_estado
                    this.detalhes.para.bairro = res.data.dados[0].destinatario_bairro
                    this.detalhes.para.rua = res.data.dados[0].destinatario_rua + ', ' + res.data.dados[0].destinatario_numero
                    this.detalhes.previsao_de_entrega.data = moment(res.data.dados[0].data_prevista).format('DD/MM/yyyy')
                    this.detalhes.previsao_de_entrega.em_domicilio = res.data.dados[0].entrega_domicilio
                    this.detalhes.entregue_em.data = res.data.dados[0].data_entrega != null ? moment(res.data.dados[0].data_entrega).format('DD/MM/yyyy') : 'Não entregue'
                })
                .finally(() => this.grid.detalhes.carregando = false)
        },
        salvarProtocoloReclamacao () {

        },
        carregarDetalhesBusca(numero_rastreio) {
            this.objetoAtual = numero_rastreio
            this.chamados.numero_rastreio = numero_rastreio
            this.modal.detalhes = true
            this.grid.detalhes.carregando = true
            let url = `${baseApiUrl}/rastreios?numero_rastreio=${numero_rastreio}`
            axios.get(url)
                .then(res => {
                    this.grid.detalhes.items = res.data.dados
                })
                .finally(() => this.grid.detalhes.carregando = false)

            this.grid.detalhes.carregando = true

            url = `${baseApiUrl}/detalhes?numero_rastreio=${numero_rastreio}`
            axios.get(url)
                .then(res => {
                    this.detalhes.enviado.data_postagem = moment(res.data.dados[0].data_postagem).format('DD/MM/yyyy')
                    this.detalhes.enviado.data_considerada = moment(res.data.dados[0].data_considerada).format('DD/MM/yyyy')
                    this.detalhes.enviado.cep = res.data.dados[0].remetente_cep
                    this.detalhes.enviado.cidade = res.data.dados[0].remetente_cidade + '/' + res.data.dados[0].remetente_estado
                    this.detalhes.para.nome = res.data.dados[0].destinatario_nome
                    this.detalhes.para.cep = res.data.dados[0].destinatario_cep
                    this.detalhes.para.cidade = res.data.dados[0].destinatario_cidade + '/' + res.data.dados[0].destinatario_estado
                    this.detalhes.para.bairro = res.data.dados[0].destinatario_bairro
                    this.detalhes.para.rua = res.data.dados[0].destinatario_rua + ', ' + res.data.dados[0].destinatario_numero
                    this.detalhes.previsao_de_entrega.data = moment(res.data.dados[0].data_prevista).format('DD/MM/yyyy')
                    this.detalhes.previsao_de_entrega.em_domicilio = res.data.dados[0].entrega_domicilio
                    this.detalhes.entregue_em.data = res.data.dados[0].data_entrega != null ? moment(res.data.dados[0].data_entrega).format('DD/MM/yyyy') : 'Não entregue'
                })
                .finally(() => this.grid.detalhes.carregando = false)
        },
    },
    watch: {
        'grid.chamados.porPagina': {
            handler: function (val, oldVal) {
                if (val != oldVal) {
                    if (val > 0) {
                        this.grid.chamados.porPagina = Number(val)
                        this.mudarPagina(1)
                    } else {
                        this.grid.chamados.porPagina = 10
                        this.mudarPagina(1)
                    }
                }
            },
        },
        'grid.chamados.coluna': {
            handler: function (val, oldVal) {
                if (val != oldVal) {
                    this.carregarChamados()
                }
            },
        },
        'grid.chamados.direcao': {
            handler: function (val, oldVal) {
                this.carregarChamados()
            },
        },
    }
}
</script>